import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 公共样式
import './styles/index.less'
// 公共样式

// aos动画库
import AOS from 'aos'
import 'aos/dist/aos.css'
// aos全局配置
AOS.init({
  duration: 1000,   
  easing: 'ease-out-back',   
  once: true, // whether animation should happen only once - while scrolling down
  
})
// aos动画库

// element
import Element from 'element-ui';
Vue.use(Element);
// element

// 引入axios
import axios from 'axios'
Vue.prototype.$ajax = axios
// 引入axios

// 自动改变font-size
import 'amfe-flexible'

// Vant组件库
// import Vant from 'vant'
// import 'vant/lib/index.css'
// Vue.use(Vant)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
