import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
    // push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    }
    // replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        name: 'enter',
        component: () =>
            import ('@/views/enter/'),
        meta: {
            title: '欢迎访问恒安康泰集团'
        }
    },
    {
        path: '/index',
        name: 'index',
        component: () =>
            import ('@/views/index/'),
        meta: {
            title: '网站首页'
        }
    },
    {
        path: '/businessSector',
        name: 'businessSector',
        component: () =>
            import ('@/views/businessSector/'),
        meta: {
            title: '业务板块'
        }
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () =>
            import ('@/views/aboutUs/'),
        meta: {
            title: '公司简介'
        }
    },
    {
        path: '/ourHonor',
        name: 'ourHonor',
        component: () =>
            import ('@/views/ourHonor/'),
        meta: {
            title: '公司荣誉'
        }
    },
    {
        path: '/advertisement',
        name: 'advertisement',
        component: () =>
            import ('@/views/advertisement/'),
        meta: {
            title: '广告展示'
        }
    },
    {
        path: '/mediaCoverage',
        name: 'mediaCoverage',
        component: () =>
            import ('@/views/mediaCoverage/'),
        meta: {
            title: '媒体报道'
        },
        // children: [{
        //         path: 'newscl1',
        //         name: 'newscl1',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl1'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl2',
        //         name: 'newscl2',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl2'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl3',
        //         name: 'newscl3',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl3'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl4',
        //         name: 'newscl4',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl4'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl5',
        //         name: 'newscl5',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl5'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl6',
        //         name: 'newscl6',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl6'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl7',
        //         name: 'newscl7',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl7'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl8',
        //         name: 'newscl8',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl8'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl9',
        //         name: 'newscl9',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl9'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl10',
        //         name: 'newscl10',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl10'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl11',
        //         name: 'newscl11',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl11'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl12',
        //         name: 'newscl12',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl12'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl13',
        //         name: 'newscl13',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl13'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl14',
        //         name: 'newscl14',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl14'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl15',
        //         name: 'newscl15',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl15'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        //     {
        //         path: 'newscl16',
        //         name: 'newscl16',
        //         component: () =>
        //             import ('@/views/mediaCoverage/newscl16'),
        //         meta: {
        //             title: '新闻详情'
        //         }
        //     },
        // ]
    },
    {
        path: '/newscl1',
        name: 'newscl1',
        component: () =>
            import ('@/views/mediaCoverage/newscl1'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl2',
        name: 'newscl2',
        component: () =>
            import ('@/views/mediaCoverage/newscl2'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl3',
        name: 'newscl3',
        component: () =>
            import ('@/views/mediaCoverage/newscl3'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl4',
        name: 'newscl4',
        component: () =>
            import ('@/views/mediaCoverage/newscl4'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl5',
        name: 'newscl5',
        component: () =>
            import ('@/views/mediaCoverage/newscl5'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl6',
        name: 'newscl6',
        component: () =>
            import ('@/views/mediaCoverage/newscl6'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl7',
        name: 'newscl7',
        component: () =>
            import ('@/views/mediaCoverage/newscl7'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl8',
        name: 'newscl8',
        component: () =>
            import ('@/views/mediaCoverage/newscl8'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl9',
        name: 'newscl9',
        component: () =>
            import ('@/views/mediaCoverage/newscl9'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl10',
        name: 'newscl10',
        component: () =>
            import ('@/views/mediaCoverage/newscl10'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl11',
        name: 'newscl11',
        component: () =>
            import ('@/views/mediaCoverage/newscl11'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl12',
        name: 'newscl12',
        component: () =>
            import ('@/views/mediaCoverage/newscl12'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl13',
        name: 'newscl13',
        component: () =>
            import ('@/views/mediaCoverage/newscl13'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl14',
        name: 'newscl14',
        component: () =>
            import ('@/views/mediaCoverage/newscl14'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl15',
        name: 'newscl15',
        component: () =>
            import ('@/views/mediaCoverage/newscl15'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/newscl16',
        name: 'newscl16',
        component: () =>
            import ('@/views/mediaCoverage/newscl16'),
        meta: {
            title: '新闻详情'
        }
    },
    {
        path: '/app',
        name: 'app',
        component: () =>
            import ('@/views/app/'),
        meta: {
            title: 'APP下载'
        }
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: () =>
            import ('@/views/contactUs/'),
        meta: {
            title: '联系我们'
        }
    },

]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, saveTop) {
        if (saveTop) {
            return saveTop;
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router